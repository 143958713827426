import React, {Children, ReactElement, RefObject, useCallback, useEffect, useRef, useState} from 'react';
import {autocomplete, AutocompleteProperties, reverse, search, suggestionDetail} from './autocompleteHelper';
import {Feature, Geometry} from 'geojson';
import {AutocompleteList} from '@components/AutocompleteList';
import {Form} from '@components/Form';
import {FormattedMessage, useIntl} from 'react-intl';
import {useLocale} from '@utils/locale';
import {ContentBox} from '@components/ContentBox';
import Autosuggest, {OnSuggestionSelected, RenderInputComponent, SuggestionsFetchRequested} from 'react-autosuggest';
import styles from './Autocomplete.module.scss';
import alStyles from '@components/AutocompleteList/AutocompleteList.module.scss';
import classNames from 'classnames';
import {Icon} from '@components/Icon';
import {Button} from '@components/Button';
import {useIntersectionObserver} from 'usehooks-ts';
import dynamic from 'next/dynamic';
import {Typography} from '@components/Typography';
import {GpsType} from '@pageComponents/Insert/types';
import {deduplicate, getCityLabel} from './utils';
import {Divider} from '@components/Divider';
import {translations} from '@constants/GeoLocation';
import {useToaster} from '@utils/toast';

const Map = dynamic(() => import('./AutocompleteMap').then((i) => i.AutocompleteMap), {ssr: false});

type AutocompleteProps = {
    value?: string;
    withPolygon?: boolean;
    address?: boolean;
    countryCode?: string;
    onSelect: (feature: Feature<Geometry, AutocompleteProperties>) => void;
    onInputChange?: Function;
    onClearInput?: Function;
    renderInputComponent?: RenderInputComponent;
    searchButtonRef?: RefObject<HTMLButtonElement>;
    placeholder?: string;
    label?: ReactElement | boolean;
    resultOverlay?: boolean;
    homePage?: boolean;
    withExtraTags?: boolean;
    withMap?: boolean;
    selectedFeature?: Feature<Geometry, AutocompleteProperties>;
    snapToNearestFeature?: boolean;
    onPointSelected?: (point: GpsType, label: string) => void;
    selectedPoint?: GpsType;
    size?: 'sm' | 'lg';
    currentLocation?: {
        setCurrentLocationUsed?: (value: boolean) => void;
        position: 'suggestionBox' | 'separate';
    };
} & (
    | {
          withMap: true;
          snapToNearestFeature?: boolean;
          onPointSelected: (point: GpsType, label: string) => void;
          selectedPoint?: GpsType;
      }
    | {
          withMap?: false;
          snapToNearestFeature?: never;
          onPointSelected?: never;
          selectedPoint?: never;
      }
);

export const Autocomplete = ({
    onInputChange,
    onClearInput,
    onSelect,
    value: initialValue,
    withPolygon,
    address,
    countryCode,
    renderInputComponent,
    searchButtonRef,
    placeholder,
    label,
    resultOverlay = true,
    homePage = false,
    withExtraTags = false,
    withMap = false,
    selectedFeature,
    snapToNearestFeature = false,
    onPointSelected,
    selectedPoint,
    size: sizeFC,
    currentLocation,
}: AutocompleteProps) => {
    const toaster = useToaster();
    const locale = useLocale();
    const intl = useIntl();
    const [abortController, setAbortController] = useState<AbortController>();
    const [suggestions, setSuggestions] = useState<Feature<Geometry, AutocompleteProperties>[]>([]);
    const [filteredSuggestions, setFilteredSuggestions] = useState<Feature<Geometry, AutocompleteProperties>[]>([]);
    const [value, setValue] = useState<string>(initialValue ?? '');
    const [hasMoreResults, setHasMoreResults] = useState<boolean>(true);
    const [loading, setLoading] = useState<boolean>(false);
    const [lastValue, setLastValue] = useState<string>('');
    const containerRef = useRef<HTMLDivElement>(null);
    const observer = useIntersectionObserver({
        root: containerRef.current,
    });

    useEffect(() => {
        setValue(initialValue ?? '');
    }, [initialValue]);

    const fetchSuggestions = useCallback(
        (value: string, from = 0) => {
            if (loading && lastValue === value) {
                return;
            }
            setLoading(true);
            setLastValue(value);
            if (abortController) {
                abortController.abort();
            }

            const controller = new AbortController();
            setAbortController(controller);

            autocomplete(
                process.env.NEXT_PUBLIC_AUTOCOMPLETE_URI ?? '',
                locale,
                countryCode,
                address,
            )(value, {signal: controller.signal}, from)
                .then((data) => {
                    if (data) {
                        const s = from === 0 ? data.features : [...suggestions, ...data.features];
                        const fs = deduplicate(from === 0 ? data.features : [...filteredSuggestions, ...data.features]);
                        setSuggestions(s);
                        setFilteredSuggestions(fs);
                        setHasMoreResults(data.features.length !== 0);
                        setLoading(false);
                    }
                })
                .catch((e) => {
                    if (e.name !== 'AbortError') {
                        console.log(e);

                        //TODO process error
                    }
                });
        },
        [abortController, suggestions, filteredSuggestions, lastValue, loading, countryCode, locale, address],
    );

    useEffect(() => {
        if (!!observer.isIntersecting && hasMoreResults && !loading) {
            fetchSuggestions(value, suggestions.length);
        }
    }, [observer.isIntersecting, hasMoreResults, loading, fetchSuggestions, value, suggestions.length]);

    const searchHandler = useCallback(() => {
        void search(
            process.env.NEXT_PUBLIC_NOMINATIM_URI ?? '',
            locale,
            withPolygon,
            withExtraTags,
        )(value).then((result) => {
            const suggestion = result.features.at(0);

            if (suggestion) {
                suggestionDetail(
                    process.env.NEXT_PUBLIC_NOMINATIM_URI ?? '',
                    process.env.NEXT_PUBLIC_STREET_URI ?? '',
                    locale,
                    withPolygon,
                    withExtraTags,
                )(suggestion)
                    .then((data) => {
                        let response = null;
                        if (data.features) {
                            response =
                                data.features.find((f: any) => f.properties.osm_id === parseInt(suggestion.properties.osm_id, 10)) ||
                                data.features[0];
                        }

                        if (response?.properties.address) {
                            suggestion.properties = Object.assign({}, suggestion.properties, {address: response.properties.address});
                        } else {
                            suggestion.properties = Object.assign({}, suggestion.properties, {
                                address: {
                                    road: suggestion.properties.street,
                                    city: suggestion.properties.city,
                                    house_number: suggestion.properties.house_number,
                                },
                            });
                        }

                        if (response && !['Point'].includes(response.geometry.type)) {
                            onSelect(Object.assign({}, suggestion, {geometry: response.geometry}));
                        } else {
                            onSelect(suggestion);
                        }
                    })
                    .catch(() => {
                        onSelect(suggestion);
                    });
                setValue(suggestion.properties.label);
            }
        });
    }, [locale, value, onSelect, withExtraTags, withPolygon]);

    useEffect(() => {
        if (searchButtonRef?.current) {
            const b = searchButtonRef.current;
            b.addEventListener('click', searchHandler);
            return () => {
                b.removeEventListener('click', searchHandler);
            };
        }
    }, [searchButtonRef, searchHandler]);

    const onSuggestionsFetchRequested: SuggestionsFetchRequested = useCallback(
        ({value}) => {
            fetchSuggestions(value);
            return;
        },
        [fetchSuggestions],
    );

    //const _onSuggestionsFetchRequested = useCallback(() => debounce(onSuggestionsFetchRequested, fetchDelay), [onSuggestionsFetchRequested, fetchDelay]);
    /*
    const onSuggestionsClearRequested = () => {
        // props.dispatch({
        //     type: ActionTypes.setAutocomplete,
        //     value: {
        //         suggestions: [],
        //     },
        // });
    };
*/
    const selectSuggestion = useCallback(
        (suggestion: Feature<Geometry, AutocompleteProperties>, currentLocationUsed = false) => {
            currentLocation?.setCurrentLocationUsed?.(currentLocationUsed);

            if (!withPolygon && !withExtraTags) {
                setValue(suggestion.properties.label);
                onSelect(suggestion);
                return;
            }

            suggestionDetail(
                process.env.NEXT_PUBLIC_NOMINATIM_URI ?? '',
                process.env.NEXT_PUBLIC_STREET_URI ?? '',
                locale,
                withPolygon,
                withExtraTags,
            )(suggestion)
                .then((data) => {
                    let response = null;
                    if (data.features) {
                        response =
                            data.features.find((f: any) => f.properties.osm_id === parseInt(suggestion.properties.osm_id, 10)) ||
                            data.features[0];
                    }

                    if (response) {
                        suggestion.properties = Object.assign({}, suggestion.properties, {
                            address: response.properties.address,
                            extratags: response.properties.extratags,
                        });
                    } else {
                        suggestion.properties = Object.assign({}, suggestion.properties, {
                            address: {
                                road: suggestion.properties.street,
                                city: suggestion.properties.city,
                                house_number: suggestion.properties.house_number,
                            },
                        });
                    }

                    if (response && !['Point'].includes(response.geometry.type)) {
                        setValue(suggestion.properties.label);
                        onSelect(Object.assign({}, suggestion, {geometry: response.geometry}));
                    } else {
                        setValue(suggestion.properties.label);
                        onSelect(suggestion);
                    }
                })
                .catch(() => {
                    setValue(suggestion.properties.label);
                    onSelect(suggestion);
                });
        },
        [onSelect, locale, withPolygon, withExtraTags],
    );

    const onSuggestionSelected: OnSuggestionSelected<Feature<Geometry, AutocompleteProperties>> = useCallback(
        (_, data) => {
            const suggestion = data.suggestion;
            selectSuggestion(suggestion);
        },
        [selectSuggestion],
    );

    const handleCurrentLocation = useCallback(() => {
        navigator.geolocation.getCurrentPosition(
            (success) => {
                const point = {
                    lng: success.coords.longitude,
                    lat: success.coords.latitude,
                };
                void reverse(
                    process.env.NEXT_PUBLIC_NOMINATIM_URI ?? '',
                    locale,
                    withPolygon,
                    withExtraTags,
                )([point.lng, point.lat]).then((result) => {
                    if (result.features.length > 0) {
                        const suggestion = result.features[0];
                        selectSuggestion(suggestion, true);
                    }
                });
            },
            (error) => {
                console.log(error);
                toaster.toast({
                    color: 'red',
                    bg: 'danger',
                    duration: 5000,
                    header: <FormattedMessage defaultMessage={'Chyba'} />,
                    message: translations[error.code as keyof typeof translations],
                });
            },
            {
                enableHighAccuracy: true,
                timeout: 5000,
            },
        );
    }, [locale, withPolygon, withExtraTags, selectSuggestion, toaster]);

    const onEnterCapture = (event: any) => {
        if (event.keyCode !== 13) {
            return;
        }

        // if (!autosuggest) {
        //     return;
        // }

        // if (autosuggest.getHighlightedSuggestion() !== null) {
        //     return;
        // }

        return onEnterWithoutHighlight();
    };

    const onEnterWithoutHighlight = () => {
        return search(
            process.env.NEXT_PUBLIC_NOMINATIM_URI ?? '',
            locale,
            withPolygon,
            withExtraTags,
        )(value).then((data) => {
            if (!data.features.length) {
                return;
            }

            console.log('enter keyboard');

            const suggestion = data.features.find((f) => f.properties.type === 'administrative') || data.features[0];
            // props.dispatch({
            //     type: ActionTypes.setAutocomplete,
            //     value: {
            //         selected: true,
            //         value: suggestion.properties.label,
            //     },
            // });

            suggestionDetail(
                process.env.NEXT_PUBLIC_NOMINATIM_URI ?? '',
                process.env.NEXT_PUBLIC_STREET_URI ?? '',
                locale,
                withPolygon,
                withExtraTags,
            )(suggestion)
                .then((data) => {
                    let response = null;
                    if (data.features) {
                        response =
                            data.features.find((f: any) => f.properties.osm_id === parseInt(suggestion.properties.osm_id, 10)) ||
                            data.features[0];
                    }

                    if (response?.properties.address) {
                        suggestion.properties = Object.assign({}, suggestion.properties, {address: response.properties.address});
                    }

                    if (response && !['Point'].includes(response.geometry.type)) {
                        onSelect(Object.assign({}, suggestion, {geometry: response.geometry}));
                        setValue(response.properties.label);
                    } else {
                        onSelect(suggestion);
                        setValue(suggestion.properties.label);
                    }
                })
                .catch(() => {
                    onSelect(suggestion);
                });
        });
    };

    const handleMapPointSelection = useCallback(
        (point: GpsType) => {
            void reverse(
                process.env.NEXT_PUBLIC_NOMINATIM_URI ?? '',
                locale,
                withPolygon,
                withExtraTags,
            )([point.lng, point.lat]).then((result) => {
                if (result.features.length > 0) {
                    const suggestion = result.features[0];
                    if (snapToNearestFeature) {
                        setValue(suggestion.properties.label);
                        onSelect(suggestion);
                    } else {
                        const label = getCityLabel(suggestion);
                        setValue(label);
                        onPointSelected?.(point, label);
                    }
                }
            });
        },
        [onSelect, snapToNearestFeature, onPointSelected, locale, withPolygon, withExtraTags],
    );

    return (
        <div className="position-relative">
            <Autosuggest
                onSuggestionSelected={onSuggestionSelected}
                onSuggestionsClearRequested={() => {
                    setSuggestions([]);
                }}
                onSuggestionsFetchRequested={onSuggestionsFetchRequested}
                suggestions={filteredSuggestions}
                getSuggestionValue={(suggestion: Feature<Geometry, AutocompleteProperties>) => {
                    return suggestion.properties.label;
                }}
                renderSuggestion={(suggestion: Feature<Geometry, AutocompleteProperties>) => {
                    const labelParts = suggestion.properties.label.split(',').map((part: string) => part.trim());

                    return (
                        <Button variant="blank" className={classNames(alStyles.autocompleteListButton)}>
                            <span className={alStyles.autocompleteListButtonIcon}>
                                <Icon name={suggestion.properties.icon ?? 'Location'} />
                            </span>

                            <span className={alStyles.autocompleteListContent}>
                                <small className={alStyles.autocompleteListType}>{suggestion.properties.local_type}</small>
                                <span>
                                    <AutocompleteList.Highlighted>{labelParts[0]}</AutocompleteList.Highlighted>
                                    {' •\u00a0'}
                                    {labelParts.slice(1).join(' •\u00a0')}
                                </span>
                            </span>
                        </Button>
                    );
                }}
                inputProps={{
                    onKeyDown: onEnterCapture,
                    onChange: (_, {newValue}) => {
                        setValue(newValue);
                        onInputChange?.(newValue);

                        if (newValue === '') {
                            onClearInput?.();
                        }

                        if (containerRef.current) {
                            containerRef.current.scrollTo({
                                top: 0,
                                behavior: 'smooth',
                            });
                        }
                    },
                    onBlur: () => {
                        const selection = suggestions.at(0);
                        if (selection) {
                            selectSuggestion(selection);
                        }
                    },
                    placeholder: placeholder ?? intl.formatMessage({defaultMessage: 'Kraj, okres, město, adresa'}),
                    value,
                }}
                renderInputComponent={
                    renderInputComponent
                        ? renderInputComponent
                        : // eslint-disable-next-line @typescript-eslint/no-unused-vars
                          ({size, ...inputProps}) => (
                              <Form.Group controlId="locality">
                                  <Form.Label>
                                      {(typeof label === 'undefined' || label === true) && (
                                          <FormattedMessage defaultMessage="Zadejte lokalitu" />
                                      )}

                                      {label}
                                  </Form.Label>
                                  <Form.Control {...inputProps} size={sizeFC} />
                              </Form.Group>
                          )
                }
                renderSuggestionsContainer={({containerProps, children}) => {
                    const {className, ref, ...restContainerProps} = containerProps;

                    return (
                        <>
                            {currentLocation?.position === 'separate' && (
                                <>
                                    <Button
                                        variant="blank"
                                        className={classNames(alStyles.autocompleteListButton, 'text-decoration-underline')}
                                        // onClick closes the box
                                        onMouseDown={handleCurrentLocation}
                                    >
                                        <Icon.Target className={'m-2'} />

                                        <span>
                                            <small className={alStyles.autocompleteListType}></small>
                                            <span>
                                                <AutocompleteList.Highlighted>
                                                    <FormattedMessage defaultMessage={'Aktuální poloha'} />
                                                </AutocompleteList.Highlighted>
                                            </span>
                                        </span>
                                    </Button>
                                </>
                            )}
                            <div
                                className={classNames('position-relative', {
                                    [styles['autocomplete--mapHolder']]: withMap,
                                })}
                            >
                                {Children.count(children) > 0 && (
                                    <ContentBox
                                        ref={(el) => {
                                            ref(el);
                                            (containerRef as any).current = el;
                                        }}
                                        {...restContainerProps}
                                        className={classNames(className, styles.autocompleteBox, {
                                            [styles['autocompleteBox--overlay']]: resultOverlay,
                                            [styles['autocompleteBox--homepage']]: homePage,
                                        })}
                                    >
                                        {currentLocation?.position === 'suggestionBox' && (
                                            <>
                                                <Button
                                                    variant="blank"
                                                    className={classNames(alStyles.autocompleteListButton, 'text-decoration-underline')}
                                                    // onClick closes the box
                                                    onMouseDown={handleCurrentLocation}
                                                >
                                                    <span className={classNames(alStyles.autocompleteListButtonIcon)}>
                                                        <Icon.Target />
                                                    </span>

                                                    <span>
                                                        <small className={alStyles.autocompleteListType}></small>
                                                        <span>
                                                            <AutocompleteList.Highlighted>
                                                                <FormattedMessage defaultMessage={'Aktuální poloha'} />
                                                            </AutocompleteList.Highlighted>
                                                        </span>
                                                    </span>
                                                </Button>
                                                <Divider className={'mt-4 mb-6'} />
                                            </>
                                        )}

                                        {children}

                                        <div
                                            ref={observer.ref}
                                            style={{
                                                height: '1px',
                                            }}
                                        />
                                    </ContentBox>
                                )}

                                {withMap && (
                                    <div className={classNames(styles.autocompleteMap)}>
                                        <Typography>
                                            <Icon.Lightbulb />
                                            <FormattedMessage defaultMessage="Adresu můžete vybrat i kliknutím do mapy" />
                                        </Typography>
                                        <Map
                                            onPointSelected={handleMapPointSelection}
                                            selectedPoint={
                                                snapToNearestFeature
                                                    ? selectedFeature?.geometry.type === 'Point'
                                                        ? {
                                                              lng: selectedFeature.geometry.coordinates[0],
                                                              lat: selectedFeature.geometry.coordinates[1],
                                                          }
                                                        : undefined
                                                    : selectedPoint
                                            }
                                        />
                                    </div>
                                )}
                            </div>
                        </>
                    );
                }}
                theme={{
                    suggestionsList: classNames(alStyles.autocompleteList, 'autocompleteList'),
                    suggestion: alStyles.autocompleteListItem,
                }}
            />
        </div>
    );
};
