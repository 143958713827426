import {Route, routes} from '@utils/routes';

export const contractRentUri = 'sc_contract_rent_online';
export const contractRentUriSk = 'sc_contract_rent_online_sk';
export const contractCompleteUri = 'sc_contract_complete';
export const creditcheckUri = 'creditcheck';
export const creditcheckUriSk = 'creditcheck_sk';
export const premiumPackageUri = 'premium_account';
export const premiumPackageUriSk = 'premium_account_sk';
export const walletChargeUri = 'wallet_charge';
export const topOfferUri = 'top_offer';
export const topOfferSkUri = 'top_offer_sk';
export const projectBasic = 'project_basic';
export const projectOptimal = 'project_optimal';
export const projectComplete = 'project_complete';
export const projectComfort = 'project_comfort';
export const projectTop1 = 'project_top_1';
export const projectTop2 = 'project_top_2';
export const projectTop3 = 'project_top_3';
export const activateUri = 'activate_advert';
export const insertBasicOfferUri = 'insert_basic_offer';
export const insertBasicOfferRentUri = 'insert_basic_offer_rent';
export const insertPromokitOfferUri = 'insert_promokit_offer';
export const insertPromokitOfferRentUri = 'insert_promokit_offer_rent';
export const insertPremiumOfferUri = 'insert_premium_offer';
export const insertPremiumOfferRentUri = 'insert_premium_offer_rent';
export const insertComfortOfferUri = 'insert_comfort_offer';
export const highlightPromokitOfferUri = 'highlight_promokit_offer';
export const highlightPromokitOfferSkUri = 'highlight_promokit_offer_sk';
export const highlightPremiumOfferUri = 'highlight_premium_offer';
export const highlightPremiumOfferSkUri = 'highlight_premium_offer_sk';
export const insertBasicOfferSkUri = 'insert_basic_offer_sk';
export const insertBasicOfferRentSkUri = 'insert_basic_offer_rent_sk';
export const insertPromokitOfferSkUri = 'insert_promokit_offer_sk';
export const insertPromokitOfferRentSkUri = 'insert_promokit_offer_rent_sk';
export const insertPremiumOfferSkUri = 'insert_premium_offer_sk';
export const insertPremiumOfferRentSkUri = 'insert_premium_offer_rent_sk';
export const advertActivateSaleBasic1 = 'advert_activate_basic_1_sale';
export const advertActivateSaleBasic2 = 'advert_activate_basic_2_sale';
export const advertActivateSaleBasic3 = 'advert_activate_basic_3_sale';
export const advertActivateSaleComplete1 = 'advert_activate_complete_1_sale';
export const advertActivateSaleComplete2 = 'advert_activate_complete_2_sale';
export const advertActivateSaleComplete3 = 'advert_activate_complete_3_sale';
export const advertExtendSaleBasic1 = 'advert_extend_basic_1_sale';
export const advertExtendSaleBasic2 = 'advert_extend_basic_2_sale';
export const advertExtendSaleBasic3 = 'advert_extend_basic_3_sale';
export const advertExtendSaleComplete1 = 'advert_extend_complete_1_sale';
export const advertExtendSaleComplete2 = 'advert_extend_complete_2_sale';
export const advertExtendSaleComplete3 = 'advert_extend_complete_3_sale';
export const advertActivateRentBasic1 = 'advert_activate_basic_1_rent';
export const advertActivateRentBasic2 = 'advert_activate_basic_2_rent';
export const advertActivateRentBasic3 = 'advert_activate_basic_3_rent';
export const advertActivateRentComplete1 = 'advert_activate_complete_1_rent';
export const advertActivateRentComplete2 = 'advert_activate_complete_2_rent';
export const advertActivateRentComplete3 = 'advert_activate_complete_3_rent';
export const advertExtendRentBasic1 = 'advert_extend_basic_1_rent';
export const advertExtendRentBasic2 = 'advert_extend_basic_2_rent';
export const advertExtendRentBasic3 = 'advert_extend_basic_3_rent';
export const advertExtendRentComplete1 = 'advert_extend_complete_1_rent';
export const advertExtendRentComplete2 = 'advert_extend_complete_2_rent';
export const advertExtendRentComplete3 = 'advert_extend_complete_3_rent';
export const advertRealmanExport = 'advert_realman_export';
export const briz = 'briz';

type serviceRouteType = Record<string, Route>;

export const serviceRoutes: serviceRouteType = {
    [contractRentUri]: routes.serviceCentreContractRent,
    [contractRentUriSk]: routes.serviceCentreContractRent,
    [contractCompleteUri]: routes.serviceCentreLegalService,
    [creditcheckUri]: routes.serviceCentreCreditcheck,
    [creditcheckUriSk]: routes.serviceCentreCreditcheck,
    [premiumPackageUri]: routes.serviceCentrePremium,
    [premiumPackageUriSk]: routes.serviceCentrePremium,
};
