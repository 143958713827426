import {addMethod, array, string, TestContext, ValidationError} from 'yup';
import {CountryCode, parsePhoneNumber} from 'libphonenumber-js';
import {DateTime, DurationUnit} from 'luxon';
import {LazyQueryExecFunction} from '@apollo/client';
import {CalendarEntriesQuery} from '@gql/query/calendar/entries.gql';
import {nonNullArray, propertyRequiredArray} from '../types/general';

addMethod(string, 'phone', function (defaultCountry: CountryCode, errorMessage: string) {
    return this.test({
        name: 'test-phone-number',
        message: errorMessage,
        skipAbsent: true,
        test: function (value: string | undefined) {
            if (typeof value !== 'string') {
                return this.createError({path: this.path, message: errorMessage});
            }
            try {
                const phone = parsePhoneNumber(value, defaultCountry);
                if (!phone.isValid()) {
                    return this.createError({path: this.path, message: errorMessage});
                }
            } catch (e) {
                return this.createError({path: this.path, message: errorMessage});
            }

            return true;
        },
    });
});

addMethod(array, 'selfIntervalOverlap', function (errorMessage: string) {
    return this.test({
        name: 'test-self-interval-overlap',
        message: errorMessage,
        test: function (value: any[] | undefined) {
            if (!value || !Array.isArray(value)) {
                return true;
            }

            try {
                value.forEach((origin, originIdx) =>
                    value.forEach((target, targetIdx) => {
                        if (originIdx === targetIdx) {
                            return;
                        }
                        //const origin = value[originIdx];
                        //const target = value[targetIdx];
                        if (
                            typeof origin !== 'object' ||
                            !origin.hasOwnProperty('from') ||
                            !origin.hasOwnProperty('to') ||
                            typeof target !== 'object' ||
                            !target.hasOwnProperty('from') ||
                            !target.hasOwnProperty('to')
                        ) {
                            throw this.createError({path: this.path, message: 'Invalid object schema'});
                        }

                        if (
                            (origin.from < target.to && origin.from >= target.from) ||
                            (target.from < origin.to && target.from >= origin.from) ||
                            (origin.to <= target.to && origin.to > target.from) ||
                            (target.to <= origin.to && target.frtoom > origin.from)
                        ) {
                            throw this.createError({path: this.path + '.' + targetIdx, message: errorMessage});
                        }
                    }),
                );
            } catch (e) {
                if (e instanceof ValidationError) {
                    return e;
                }
                throw e;
            }

            return true;
        },
    });
});

addMethod(array, 'intervalOverlap', function (intervals: {from: Date; to: Date}[], errorMessage: string) {
    return this.test({
        name: 'test-interval-overlap',
        message: errorMessage,
        test: function (value: any[] | undefined) {
            if (!value || !Array.isArray(value)) {
                return true;
            }

            try {
                value.forEach((origin) =>
                    intervals.forEach((origTarget, targetIdx) => {
                        //const origin = value[originIdx];
                        const originFromDate = DateTime.fromJSDate(origin.from);
                        const originToDate = DateTime.fromJSDate(origin.to);
                        //const origTarget = intervals[targetIdx];
                        const target = {
                            from: DateTime.fromJSDate(origTarget.from)
                                .set({
                                    day: originFromDate.day,
                                    month: originFromDate.month,
                                    year: originFromDate.year,
                                })
                                .toJSDate(),
                            to: DateTime.fromJSDate(origTarget.to)
                                .set({
                                    day: originToDate.day,
                                    month: originToDate.month,
                                    year: originToDate.year,
                                })
                                .toJSDate(),
                        };
                        if (typeof origin !== 'object' || !origin.hasOwnProperty('from') || !origin.hasOwnProperty('to')) {
                            throw this.createError({path: this.path, message: 'Invalid object schema'});
                        }

                        if (
                            (origin.from < target.to && origin.from >= target.from) ||
                            (target.from < origin.to && target.from >= origin.from) ||
                            (origin.to <= target.to && origin.to > target.from) ||
                            (target.to <= origin.to && target.to > origin.from)
                        ) {
                            throw this.createError({path: this.path + '.' + targetIdx, message: errorMessage});
                        }
                    }),
                );
            } catch (e) {
                if (e instanceof ValidationError) {
                    return e;
                }

                throw e;
            }

            return true;
        },
    });
});

addMethod(array, 'intervalLazyOverlap', function (getter: LazyQueryExecFunction<CalendarEntriesQuery, any>, errorMessage: string) {
    return this.test({
        name: 'test-interval-overlap',
        message: errorMessage,
        test: async function (value: any[] | undefined, context: TestContext) {
            const data = await getter({
                variables: {
                    from: DateTime.fromJSDate(context.parent.dateFrom).toSeconds(),
                    to: DateTime.fromJSDate(context.parent.dateTo).endOf('day').set({millisecond: 0}).toSeconds(),
                },
            });
            const intervals = propertyRequiredArray(nonNullArray(data.data?.calendarEntryList?.list ?? []), ['timeTo', 'timeFrom']).map(
                (entry) => ({
                    from: DateTime.fromSeconds(entry.timeFrom).toJSDate(),
                    to: DateTime.fromSeconds(entry.timeTo).toJSDate(),
                }),
            );
            if (!value || !Array.isArray(value)) {
                return true;
            }

            try {
                value.forEach((origin, originIdx) =>
                    intervals.forEach((origTarget) => {
                        //const origin = value[originIdx];
                        const originFromDate = DateTime.fromJSDate(origin.from);
                        const originToDate = DateTime.fromJSDate(origin.to);
                        //const origTarget = intervals[targetIdx];
                        const target = {
                            from: DateTime.fromJSDate(origTarget.from)
                                .set({
                                    day: originFromDate.day,
                                    month: originFromDate.month,
                                    year: originFromDate.year,
                                })
                                .toJSDate(),
                            to: DateTime.fromJSDate(origTarget.to)
                                .set({
                                    day: originToDate.day,
                                    month: originToDate.month,
                                    year: originToDate.year,
                                })
                                .toJSDate(),
                        };
                        if (typeof origin !== 'object' || !origin.hasOwnProperty('from') || !origin.hasOwnProperty('to')) {
                            throw this.createError({path: this.path, message: 'Invalid object schema'});
                        }

                        if (
                            (origin.from < target.to && origin.from >= target.from) ||
                            (target.from < origin.to && target.from >= origin.from) ||
                            (origin.to <= target.to && origin.to > target.from) ||
                            (target.to <= origin.to && target.to > origin.from)
                        ) {
                            throw this.createError({path: this.path + '.' + originIdx, message: errorMessage});
                        }
                    }),
                );
            } catch (e) {
                if (e instanceof ValidationError) {
                    return e;
                }

                throw e;
            }

            return true;
        },
    });
});

addMethod(array, 'minIntervalLength', function (length: number, unit: DurationUnit, errorMessage: string) {
    return this.test({
        name: 'test-min-interval-length',
        message: errorMessage,
        test: function (value: any) {
            if (!value || !Array.isArray(value)) {
                return true;
            }

            try {
                value.forEach((origin, originIdx) => {
                    //const origin = value[originIdx];
                    if (typeof origin !== 'object' || !origin.hasOwnProperty('from') || !origin.hasOwnProperty('to')) {
                        throw this.createError({path: this.path, message: 'Invalid object schema'});
                    }

                    if (DateTime.fromJSDate(origin.to).diff(DateTime.fromJSDate(origin.from)).as(unit) < length - 1) {
                        throw this.createError({path: this.path + '.' + originIdx, message: errorMessage});
                    }
                });
            } catch (e) {
                if (e instanceof ValidationError) {
                    return e;
                }
                throw e;
            }

            return true;
        },
    });
});

addMethod(array, 'isUpcomingInterval', function (errorMessage: string) {
    return this.test({
        name: 'test-is-upcoming-interval',
        message: errorMessage,
        test: function (value: any, context: TestContext) {
            if (!value || !Array.isArray(value) || !context.parent.dateFrom || !context.parent.dateTo) {
                return true;
            }

            try {
                const today = DateTime.now().startOf('day');
                const startDate = DateTime.fromJSDate(context.parent.dateFrom);
                const endDate = DateTime.fromJSDate(context.parent.dateTo);

                if (endDate && endDate.diff(today, 'days').as('days') >= 1) {
                    return true;
                }

                value.forEach((origin, originIdx) => {
                    const originDate = DateTime.fromJSDate(origin.from);

                    if (startDate.set({hour: originDate.hour, minute: originDate.minute}).diffNow('minutes').as('minutes') <= 0) {
                        throw this.createError({path: this.path + '.' + originIdx, message: errorMessage});
                    }
                });

                return true;
            } catch (e) {
                if (e instanceof ValidationError) {
                    return e;
                }
                throw e;
            }
        },
    });
});
