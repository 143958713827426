import classNames from 'classnames';
import React, {CSSProperties, ElementType, FC, MouseEventHandler} from 'react';
import {Color} from '../../types/colors';

export type TypographyProps = {
    as?: ElementType;
    variant?:
        | 'title'
        | 'h1'
        | 'h2'
        | 'h3'
        | 'h4'
        | 'h5'
        | 'h6'
        | 'subheadline'
        | 'subheadline-lg'
        | 'perex'
        | 'perex-lg'
        | 'body'
        | 'caption'
        | 'link-perex'
        | 'link-body'
        | 'cta'
        | 'small'
        | 'xs';
    color?: Color;
    fontWeight?: 'lighter' | 'light' | 'normal' | 'medium' | 'semibold' | 'bold' | 'bolder';
    textTransform?: 'lowercase' | 'uppercase' | 'capitalize' | 'none';
    textAlign?: 'start' | 'center' | 'end';
    whiteSpace?: 'normal' | 'nowrap';
    decoration?: 'underline' | 'line-through' | 'overline' | 'none';
    truncate?: boolean | number;
    letterSpacing?: boolean;
    className?: string;
    onClick?: MouseEventHandler;
    id?: string;
    style?: CSSProperties;
};

const HEADINGS = ['h1', 'h2', 'h3', 'h4', 'h5', 'h6'];

export const Typography: FC<React.PropsWithChildren<TypographyProps>> = ({
    as = 'span',
    variant,
    color,
    fontWeight,
    textTransform,
    textAlign,
    whiteSpace,
    truncate,
    letterSpacing,
    className,
    children,
    style,
    decoration,
    ...props
}) => {
    const Component = as;

    return (
        <Component
            {...props}
            className={classNames(className, {
                [`text-${variant}`]: variant && !HEADINGS.includes(variant),
                [`${variant}`]: variant && HEADINGS.includes(variant),
                [`text-${color}`]: color,
                [`fw-${fontWeight}`]: fontWeight,
                [`text-${textTransform}`]: textTransform,
                [`text-${textAlign}`]: textAlign,
                [`text-${whiteSpace}`]: whiteSpace,
                [`text-decoration-${decoration}`]: decoration,
                [`text-truncate${typeof truncate === 'number' ? `-multiple` : ''}`]: truncate,
                [`text-ls`]: letterSpacing === true,
                [`text-ls-normal`]: letterSpacing === false,
            })}
            style={{...style, WebkitLineClamp: typeof truncate === 'boolean' ? (truncate ? 1 : undefined) : truncate}}
        >
            {children}
        </Component>
    );
};
