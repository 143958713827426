import {FormattedMessage, useIntl} from 'react-intl';
import {ContactBox as CB} from '@components/ContactBox';
import {LocaleEnum} from '@localTypes/general';
import {useLocale} from '@utils/locale';

type ContactBoxComfortProps = {
    withChatBot?: boolean;
};

export const ContactBoxComfort = ({withChatBot}: ContactBoxComfortProps) => {
    const intl = useIntl();
    const locale = useLocale();

    return (
        <CB>
            <CB.Heading>
                <FormattedMessage defaultMessage="Rádi vám poradíme" />
            </CB.Heading>

            <CB.Link href={'tel:' + intl.formatMessage({defaultMessage: '+420800444411'})} iconName="Phone">
                <FormattedMessage defaultMessage={'+420 800 444 411'} />
                <CB.LinkDesc>
                    <FormattedMessage defaultMessage="Po - Pá (8:30 - 17:00)" />
                </CB.LinkDesc>
            </CB.Link>

            <CB.Link href={'mailto:' + intl.formatMessage({defaultMessage: 'info@bezrealitky.cz'})} iconName="Envelope">
                <FormattedMessage defaultMessage={'info@bezrealitky.cz'} />
            </CB.Link>

            {withChatBot && locale !== LocaleEnum.en && (
                <CB.Link
                    href="#"
                    iconName="Chat"
                    onClick={(e) => {
                        e.preventDefault();

                        const btns = document.getElementsByClassName('dw-button');

                        if (btns.length > 0) {
                            const btn = btns[0] as HTMLElement;
                            btn.click();
                        }
                    }}
                >
                    <FormattedMessage defaultMessage={'Spustit chat'} />
                </CB.Link>
            )}
        </CB>
    );
};
