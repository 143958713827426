import * as Types from '../../../../.cache/__types__';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type AuthorizedUserQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type AuthorizedUserQuery = { __typename?: 'Query', authUser?: { __typename?: 'User', id?: string | null, firstname?: string | null, lastname?: string | null, email?: string | null, phone?: string | null, city?: string | null, photo?: string | null, accountType?: Types.AccountType | null, timeAccountExpiration?: number | null, locale?: Types.Locale | null, isOwner?: boolean | null, isDeveloper?: boolean | null, canSetAdvertFee?: boolean | null, canAddWatchdog?: boolean | null, voucherRewardReceived?: boolean | null, importType?: Types.AdvertImportType | null, brizCount: number, insuranceStatus?: Types.InsuranceStatus | null, checkStatus?: Types.CreditcheckCheckStatus | null, noteIsFilled?: boolean | null, yearBorn?: number | null, familyMembers?: number | null, workplace?: string | null, education?: Types.Education | null, phoneVerified?: boolean | null, pushSubscriptions?: Array<{ __typename?: 'PushSubscription', id?: string | null, timeCreated?: number | null, deviceName?: string | null } | null> | null } | null };


export const AuthorizedUserDocument = gql`
    query AuthorizedUser {
  authUser {
    id
    firstname
    lastname
    email
    phone
    city
    photo(filter: USER_PHOTO)
    accountType
    timeAccountExpiration
    locale
    isOwner
    isDeveloper
    canSetAdvertFee
    phoneVerified: phoneValid
    canAddWatchdog
    pushSubscriptions {
      id
      timeCreated
      deviceName
    }
    voucherRewardReceived
    importType
    brizCount
    insuranceStatus
    checkStatus
    noteIsFilled
    yearBorn
    familyMembers
    workplace
    education
  }
}
    `;

/**
 * __useAuthorizedUserQuery__
 *
 * To run a query within a React component, call `useAuthorizedUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useAuthorizedUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAuthorizedUserQuery({
 *   variables: {
 *   },
 * });
 */
export function useAuthorizedUserQuery(baseOptions?: Apollo.QueryHookOptions<AuthorizedUserQuery, AuthorizedUserQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AuthorizedUserQuery, AuthorizedUserQueryVariables>(AuthorizedUserDocument, options);
      }
export function useAuthorizedUserLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AuthorizedUserQuery, AuthorizedUserQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AuthorizedUserQuery, AuthorizedUserQueryVariables>(AuthorizedUserDocument, options);
        }
export function useAuthorizedUserSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<AuthorizedUserQuery, AuthorizedUserQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<AuthorizedUserQuery, AuthorizedUserQueryVariables>(AuthorizedUserDocument, options);
        }
export type AuthorizedUserQueryHookResult = ReturnType<typeof useAuthorizedUserQuery>;
export type AuthorizedUserLazyQueryHookResult = ReturnType<typeof useAuthorizedUserLazyQuery>;
export type AuthorizedUserSuspenseQueryHookResult = ReturnType<typeof useAuthorizedUserSuspenseQuery>;
export type AuthorizedUserQueryResult = Apollo.QueryResult<AuthorizedUserQuery, AuthorizedUserQueryVariables>;